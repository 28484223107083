<template>
<div id="page-top">
  <!-- Navigation-->
  <nav class="navbar navbar-expand-lg navbar-light fixed-top" id="mainNav">
            <div class="container px-4 px-lg-5">
                <a class="navbar-brand" href="#page-top"><img class="img-fluid logo" src="./assets/logo-.png" alt="..." /></a>
                <button class="navbar-toggler navbar-toggler-right" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                    Menu
                    <i class="fas fa-bars"></i>
                </button>
                <div class="collapse navbar-collapse" id="navbarResponsive">
                    <ul class="navbar-nav ms-auto">
                        <li class="nav-item"><a class="nav-link" href="#about">About</a></li>
                        <li class="nav-item"><a class="nav-link" href="#projects">Drones</a></li>
                        <li class="nav-item"><a class="nav-link" href="#signup">Contact</a></li>
                    </ul>
                </div>
            </div>
        </nav>
        <!-- Masthead-->
        <header class="masthead">
            <div class="container px-4 px-lg-5 d-flex h-100 align-items-center justify-content-center">
                <div class="d-flex justify-content-center">
                    <div class="text-center">
                        <h1 class="mx-auto my-0 text-uppercase">Sky Rovr</h1>
                        <h2 class="text-white-50 mx-auto mt-2 mb-5">Discover and explore the world from above with Sky Rovr drones.</h2>
                        <a class="btn btn-primary" href="#about">Explore Our Story</a>
                    </div>
                </div>
            </div>
        </header>
        <!-- About-->
        <section class="about-section text-center" id="about">
            <div class="container px-4 px-lg-5">
                <div class="row gx-4 gx-lg-5 justify-content-center">
                    <div class="col-lg-8">
                        <h2 class="text-white mb-4">Empowering Aerial Innovation</h2>
                        <p class="text-white-50">
                          Sky Rovr is an innovative drone manufacturer, specializing in cutting-edge aerial technology. With a focus on versatility, reliability, and performance, we offer three distinct drone models tailored to meet diverse operational needs in photography, surveying, and security.
                        </p>
                    </div>
                </div>
                <img class="img-fluid drone-img-i" src="./assets/1-n-bg.png" alt="..." />
            </div>
        </section>
        <!-- Projects-->
        <section class="projects-section bg-light" id="projects">
            <div class="container px-4 px-lg-5">
                <!-- Featured Project Row-->
                <div class="row gx-0 mb-4 mb-lg-5 align-items-center">
                    <div class="col-xl-8 col-lg-7"><img class="img-fluid mb-3 mb-lg-0" src="./assets/1.jpeg" alt="..." /></div>
                    <div class="col-xl-4 col-lg-5">
                        <div class="featured-text text-center text-lg-left">
                            <h4>QuickDrop (E-commerce Drone)</h4>
                            <p class="text-black-50 mb-0">QuickDrop is designed to deliver packages swiftly and efficiently for e-commerce platforms. Equipped with GPS navigation and lightweight materials, it optimizes delivery times while maintaining a low energy footprint. Its compact design allows it to handle multiple deliveries within urban and suburban areas.</p>
                        </div>
                    </div>
                </div>
                <!--row-->
                <div class="row gx-0 mb-4 mb-lg-5 align-items-center">
                    <div class="col-xl-8 col-lg-7"><img class="img-fluid mb-3 mb-lg-0" src="./assets/agri.jpeg" alt="..." /></div>
                    <div class="col-xl-4 col-lg-5">
                        <div class="featured-text text-center text-lg-left">
                            <h4>AgriFly (Agriculture Drone)</h4>
                            <p class="text-black-50 mb-0">AgriFly is built to support farmers by conducting aerial crop surveys, detecting pests, and assisting with precision agriculture. Its sensors provide real-time data on soil health and crop conditions to improve yield outcomes. This drone’s durable build makes it suitable for diverse agricultural terrains.</p>
                        </div>
                    </div>
                </div>
                <!--row-->
                <div class="row gx-0 mb-4 mb-lg-5 align-items-center">
                    <div class="col-xl-8 col-lg-7"><img class="img-fluid mb-3 mb-lg-0" src="./assets/3.jpeg" alt="..." /></div>
                    <div class="col-xl-4 col-lg-5">
                        <div class="featured-text text-center text-lg-left">
                            <h4>SentryEye (Surveillance and Security Drone)</h4>
                            <p class="text-black-50 mb-0">SentryEye is designed for 24/7 surveillance with high-definition cameras and night vision capabilities. Ideal for securing large areas like warehouses, campuses, or remote facilities, it can detect movement and alert security teams instantly. The drone’s AI-enhanced detection helps monitor suspicious activities and maintain safety.</p>
                        </div>
                    </div>
                </div>
                <!--row-->
                <div class="row gx-0 mb-4 mb-lg-5 align-items-center">
                    <div class="col-xl-8 col-lg-7"><img class="img-fluid mb-3 mb-lg-0" src="./assets/4.jpeg" alt="..." /></div>
                    <div class="col-xl-4 col-lg-5">
                        <div class="featured-text text-center text-lg-left">
                            <h4>RescueRanger (Fire and Rescue Drone)</h4>
                            <p class="text-black-50 mb-0">RescueRanger is engineered to assist in emergency situations by locating people and assessing hazardous areas. With thermal imaging and live-streaming capabilities, it provides real-time visuals to rescue teams. The drone is highly durable and can operate in extreme environments, aiding in effective, timely rescues.</p>
                        </div>
                    </div>
                </div>
                <!--row-->
                <div class="row gx-0 mb-4 mb-lg-5 align-items-center">
                    <div class="col-xl-8 col-lg-7"><img class="img-fluid mb-3 mb-lg-0" src="./assets/4.jpeg" alt="..." /></div>
                    <div class="col-xl-4 col-lg-5">
                        <div class="featured-text text-center text-lg-left">
                            <h4>SiteScan (Engineering Inspection Drone)</h4>
                            <p class="text-black-50 mb-0">SiteScan is tailored for structural inspections of construction sites, bridges, and other critical infrastructures. Equipped with high-resolution cameras and 3D mapping technology, it captures precise data for analysis and reporting. SiteScan improves efficiency and safety by reducing the need for manual inspections in challenging locations.</p>
                        </div>
                    </div>
                </div>
                <!-- Project One Row-->
                <!-- <div class="row gx-0 mb-5 mb-lg-0 justify-content-center">
                    <div class="col-lg-6 drone-ii"><img class="img-fluid" src="./assets/drone2.webp" alt="..." /></div>
                    <div class="col-lg-6">
                        <div class="bg-black text-center h-100 project">
                            <div class="d-flex h-100">
                                <div class="project-text w-100 my-auto text-center text-lg-left">
                                    <h4 class="text-white">Sky Rovr Hawk</h4>
                                    <p class="mb-0 text-white-50">The Hawk is built for endurance and efficiency, perfect for extensive surveying and mapping tasks. With its robust build and enhanced battery life, it can cover vast areas, providing detailed and accurate data collection.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <!-- Project Two Row-->
                <!-- <div class="row gx-0 justify-content-center">
                    <div class="col-lg-6 drone-ii"><img class="img-fluid" src="./assets/drone3.webp" alt="..." /></div>
                    <div class="col-lg-6 order-lg-first">
                        <div class="bg-black text-center h-100 project">
                            <div class="d-flex h-100">
                                <div class="project-text w-100 my-auto text-center text-lg-right">
                                    <h4 class="text-white">Sky Rovr Eagle</h4>
                                    <p class="mb-0 text-white-50">The Eagle is a high-performance drone optimized for security and surveillance operations. Featuring state-of-the-art sensing technology, it delivers real-time monitoring and rapid response capabilities, ensuring unmatched security coverage.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </section>
        <!-- Signup-->
        <section class="signup-section" id="signup">
            <div class="container px-4 px-lg-5">
                <div class="row gx-4 gx-lg-5">
                    <div class="col-md-10 col-lg-8 mx-auto text-center">
                        <i class="far fa-paper-plane fa-2x mb-2 text-white"></i>
                        <h2 class="text-white mb-5">Subscribe to receive updates!</h2>
                        <!-- * * * * * * * * * * * * * * *-->
                        <!-- * * SB Forms Contact Form * *-->
                        <!-- * * * * * * * * * * * * * * *-->
                        <!-- This form is pre-integrated with SB Forms.-->
                        <!-- To make this form functional, sign up at-->
                        <!-- https://startbootstrap.com/solution/contact-forms-->
                        <!-- to get an API token!-->
                        <form class="form-signup" id="contactForm" data-sb-form-api-token="API_TOKEN">
                            <!-- Email address input-->
                            <div class="row input-group-newsletter">
                                <div class="col"><input class="form-control" id="emailAddress" type="email" placeholder="Enter email address..." aria-label="Enter email address..." data-sb-validations="required,email" /></div>
                                <div class="col-auto"><button class="btn btn-primary disabled" id="submitButton" type="submit">Notify Me!</button></div>
                            </div>
                            <div class="invalid-feedback mt-2" data-sb-feedback="emailAddress:required">An email is required.</div>
                            <div class="invalid-feedback mt-2" data-sb-feedback="emailAddress:email">Email is not valid.</div>
                            <!-- Submit success message-->
                            <!---->
                            <!-- This is what your users will see when the form-->
                            <!-- has successfully submitted-->
                            <div class="d-none" id="submitSuccessMessage">
                                <div class="text-center mb-3 mt-2 text-white">
                                    <div class="fw-bolder">Form submission successful!</div>
                                    <!-- To activate this form, sign up at
                                    <br />
                                    <a href="https://startbootstrap.com/solution/contact-forms">https://startbootstrap.com/solution/contact-forms</a> -->
                                </div>
                            </div>
                            <!-- Submit error message-->
                            <!---->
                            <!-- This is what your users will see when there is-->
                            <!-- an error submitting the form-->
                            <div class="d-none" id="submitErrorMessage"><div class="text-center text-danger mb-3 mt-2">Error sending message!</div></div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
        <!-- Contact-->
        <section class="contact-section bg-black">
            <div class="container px-4 px-lg-5">
                <div class="row gx-4 gx-lg-5">
                    <div class="col-md-4 mb-3 mb-md-0">
                        <div class="card py-4 h-100">
                            <div class="card-body text-center">
                                <i class="fas fa-map-marked-alt text-primary mb-2"></i>
                                <h4 class="text-uppercase m-0">Address</h4>
                                <hr class="my-4 mx-auto" />
                                <div class="small text-black-50">North Salt Lake, Utah, 84054, US</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 mb-3 mb-md-0">
                        <div class="card py-4 h-100">
                            <div class="card-body text-center">
                                <i class="fas fa-envelope text-primary mb-2"></i>
                                <h4 class="text-uppercase m-0">Email</h4>
                                <hr class="my-4 mx-auto" />
                                <div class="small text-black-50"><a href="#!">info@skyrovr.com</a></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 mb-3 mb-md-0">
                        <div class="card py-4 h-100">
                            <div class="card-body text-center">
                                <i class="fas fa-mobile-alt text-primary mb-2"></i>
                                <h4 class="text-uppercase m-0">Office Hours</h4>
                                <hr class="my-4 mx-auto" />
                                <div class="small text-black-50">Monday to Friday, 9 AM - 5 PM</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="social d-flex justify-content-center">
                    <a class="mx-2" href="#!"><i class="fab fa-twitter"></i></a>
                    <a class="mx-2" href="#!"><i class="fab fa-facebook-f"></i></a>
                    <a class="mx-2" href="#!"><i class="fab fa-github"></i></a>
                </div>
            </div>
        </section>
        <!-- Footer-->
        <footer class="footer bg-black small text-center text-white-50"><div class="container px-4 px-lg-5">Copyright &copy; Sky Rovr 2024</div></footer>
</div>  
</template>

<script>
export default {
  name: 'App',
};
</script>

<style>
.drone-ii
{
  background-color:#000;
}
.logo
{
    max-height:57px;
}
.drone-img-i
{
    max-height: 450px;
}
</style>
